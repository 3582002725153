/**
 * vuex
 * 在modules文件夹下创建相应的文件，无需其他操作
 * **/

import Vuex from "vuex";
import Vue from "vue";
import storageUtils from "@/utils/storageUtils";

const path = require("path");

Vue.use(Vuex);
const files = require.context("./modules", false, /\.js/);
const modules = {};
files.keys().forEach((key) => {
    const name = path.basename(key, ".js");
    modules[name] = files(key).default;
});

const store = new Vuex.Store({
    modules,
});

/**
 * 初始化vuex数据
 **/
export function setupStore() {
    store.commit('user/changeUser',storageUtils.getItem('user'));
    store.commit('user/changeCart',storageUtils.getItem('cart')||[]);
    store.commit('user/changeOrderGoodsList',storageUtils.getItem('orderGoodsList'))
}

export default store;
