import {validMobile} from "@/utils/validateUtils";
export {dateFormat} from '@/utils/dateUtils'

/**
* @Description:手机号操作隐藏中间四位
*/
export function formatMobile(mobile) {
    if(validMobile(mobile)){
        const  pho=/(\d{3})\d*(\d{4})/;
        return mobile.replace(pho,'$1****$2');
    }
    return ''
}

/**
* @Description:数字操作保留消暑
*/
export function formatNum(num, digit = 2){
    if(num){
        return Number(num.toFixed(2))
    }
    return 0
}
/**
* @Description:处理发货时间
*/
export function formatDeliveryTime(item){
    if (item.deliverDays && item.deliverDaysMax) {
        return `预计付款后${item.deliverDays}~${item.deliverDaysMax}内发货`
    }
    if (item.deliverDays) {
        return `预计付款后${item.deliverDays}后发货`
    }
    if (item.deliverDaysMax) {
        return `预计付款后${item.deliverDaysMax}内发货`
    }
}