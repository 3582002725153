import Http from "@/request/http";
import {Message} from "element-ui";
import storageUtils from "@/utils/storageUtils";
import store from "@/store";
import {handlePath} from "@/utils/commonUtils";

const request = new Http({
    showLoading: true,
    baseURL: process.env.VUE_APP_URL,
    showError: true,
    interceptors: {
        requestInterceptor: (config) => {
            config.headers.appid = process.env.VUE_APP_APPID;
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            if (!config.data) {
                config.data = {};
            }
            const token = storageUtils.getItem('token') || 'b7c398158087afb9c71e9ae35af2c26b_1';
            if (token) {
                config.headers.token = token
            }
            const business = storageUtils.getItem('business');
            if (business) {
                config.headers.empId = business.empId;
                config.data.businessId = business.businessId;
            }
            return config
        },
        responseInterceptor: (res) => {
            const data = res.data;
            // 跳转到登录页
            if (data.error) {
                if (data.code === '300') {
                    handlePath('/login')
                    storageUtils.clear();
                    store.commit('user/resetData')
                }
                Message.warning(data.message)
                return Promise.reject(data)
            }
            return res.data.data

        }
    }
})


export default request
