import AppidApi from "@/api/appidApi";
import storageUtils from "@/utils/storageUtils";
import {loadImg} from "@/utils/fileUtils";

const base = {
    namespaced: true,
    state: {
        scrollTop: 0,
        business: {},
        visible: false,//密码弹窗是否显示
        checked: false,//密码是否已经校验
        to: {
            path: "",
            query: {}
        }
    },
    mutations: {
        // 记录window滚动的距离
        changeScrollTop(state, scrollTop) {
            state.scrollTop = scrollTop;
        },
        changeBusiness(state, business) {
            storageUtils.setItem('business', business)
            state.business = business;
        },
        // 修改弹窗是否显示
        changeVisible(state, visible = true) {
            state.visible = visible
        },
        //  是否校验密码
        changeChecked(state) {
            state.checked = !state.checked;
        },
        //     记录路由
        changeTo(state,to){
            state.to=to;
        }
    },
    getters: {},
    actions: {
        async getBusiness(context) {
            const res = await AppidApi.business();
            context.commit('changeBusiness', res);
            document.title = res.name;
            const link = document.querySelector("link[rel*='icon']");
            link.href = loadImg(res.logo)
        },
    },
};
export default base;
