import Router from "@/router";

/**
 * @Description: 滚动页面到顶部
 */
export function scrollTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

/**
 * @Description:这个方法是处理后台返回的富文本数据，主要是处理图片地址
 */
export function replaceRichText(richText) {
    const regex = /<img[^>]*\ssrc\s*=\s*['"]\/[^'"]*['"][^>]*>/g;
    // 使用正则表达式的 replace 方法来遍历并替换所有匹配项
    richText = richText.replace(regex, (match) => {
        // 提取 src 参数
        const srcMatch = match.match(/src\s*=\s*['"]([^'"]*)['"]/);
        if (srcMatch && srcMatch[1].startsWith('/')) {
            const src = srcMatch[1];
            // 截取id
            const idMatch = src.match(/\?id=(\d+)/);
            if (idMatch) {
                // 这里替换为你需要的新地址
                const newSrc = `${process.env.VUE_APP_URL}${process.env.VUE_APP_IMG_BASE}${idMatch[1]}`;
                // 替换 src 属性值
                return match.replace(srcMatch[0], `src="${newSrc}"`);
            }
        }
        // 如果没有匹配到 id 或者 src 不是以 / 开头，则返回原始的 img 标签
        return match;
    });
    return richText
}

/**
 * @Description:跳转页面
 */
export function handlePath(path, query = {}, target = 'self') {
    if (target === 'self') {
        Router.push({path, query})
    }
    if (target === 'blank') {
        let routerData = Router.resolve({
            path,
            query
        });
        window.open(routerData.href)
    }
}

/** 处理 富文本图片 格式*/
export function handleRichTextImg(data) {
    let item = data;
    // 表达式来匹配 img 标签及其 src 属性
    const regex = /<img[^>]*\ssrc\s*=\s*['"]\/[^'"]*['"][^>]*>/g;
    // 使用正则表达式的 replace 方法来遍历并替换所有匹配项
    item = item.replace(regex, (match) => {
        // 提取 src 参数
        const srcMatch = match.match(/src\s*=\s*['"]([^'"]*)['"]/);
        if (srcMatch && srcMatch[1].startsWith('/')) {
            const src = srcMatch[1];
            // 截取id
            const idMatch = src.match(/\?id=(\d+)/);
            if (idMatch) {
                // 这里替换为你需要的新地址
                let newSrc = `${process.env.VUE_APP_URL}${process.env.VUE_APP_IMG_BASE}${idMatch[1]}`;
                // 替换 src 属性值
                if (newSrc.indexOf('x-oss-process=image/resize') === -1) {
                    // 判断是否有？
                    if (newSrc.indexOf('?') > 0) {
                        newSrc = newSrc + '&x-oss-process=image/resize,w_750'
                    } else {
                        newSrc = newSrc + '?x-oss-process=image/resize,w_750'
                    }
                    return match.replace(srcMatch[0], `src="${newSrc}"`);
                }
            }
        }
        // 如果没有匹配到 id 或者 src 不是以 / 开头，则返回原始的 img 标签
        return match;
    });
    return item
}