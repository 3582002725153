import request from "@/request";
import storageUtils from "@/utils/storageUtils";
import { Message } from "element-ui";
import { delay } from "lodash";

/**
 * @Description:用户(免登录)
 */
class VipApi {
	/**
	 * @Description:获取注册验证码
	 * @param {Object} data
	 * @param {string} data.mobile 手机号
	 */
	registerMobileCode(data){
		return request.post({
			url: "/index/mobile/vip/register-mobile-code",
			data
		})
	}
	
	/**
	 * @Description:用户注册-用户名密码
	 * @param {Object} data
	 * @param {string} data.username 用户名
	 * @param {string} data.password 密码
	 * @param {string} data.code 短信验证码
	 */
	registerPwd(data){
		return request.post({
			url: '/index/mobile/vip/register-pwd',
			data
		})
	}
	
	/**
	 * @Description:获取当前登录用户详情
	 */
	get(){
		return request.get({
			url: '/mobile/vip/get'
		})
	}
	
	/**
	 * @Description:用户名密码登录
	 * @param {Object} data
	 * @param {string} data.username 用户名
	 * @param {string} data.password 密码
	 */
	login(data){
		return request.post({
			url: "/index/mobile/vip/login",
			data
		})
	}
	
	/**
	 * @Description:退出登录
	 */
	logout(){
		return request.get({
			url: "/mobile/vip/logout "
		}).then(res => {
			Message.success('退出成功');
			delay(() => {
				storageUtils.removeItem('token');
				storageUtils.removeItem('user');
				storageUtils.removeItem('cart');
				window.location.href = '/'
			}, 1500)
		})
	}
	
	/**
	 * @Description:手机号验证码登录
	 */
	loginByCode(data){
		return request.post({
			url: "/index/mobile/vip/loginByCode",
			data
		})
	}
	
	/** 判断是否登录*/
	isLogin(){
		return !!storageUtils.getItem('token');
	}
	
	/** 修改 用户信息 */
	update(data,attachmentIds){
		return request.post({
			url: "/mobile/vip/update",
			data,
			params:{
				attachmentIds
			}
		})
	}
	/**
	* @Description:获取用户中心的统计数据
	*/
	getCenter(){
		return request.get({
			url:"/mobile/vip/center"
		})
	}
}

export default new VipApi()