export function loadImg(src, width, height, text, size){
	if ( !src) return "";
	if (src.startsWith("http")) {
		// 判断是否是oss
		let oss = src.indexOf('aliyuncs.com') > 0 && src.indexOf('oss-cn-') > 0 && src.indexOf('x-oss-process') === -1
		let prefix = src.indexOf('?') === -1 ? '?' : '&';
		if (oss && height) {
			src = src + prefix + 'x-oss-process=image/resize,h_' + height + ',m_lfit';
		}
	}
	
	// 如果是id，则拼接地址
	if (/^\d+$/g.test(src)) {
		src = `${process.env.VUE_APP_URL}/index/mobile/attachment/view/${src}`;
		if (width) {
			src += `?x-oss-process=image/resize,w_${width},m_lfit`;
		} else if (height) {
			src += `?x-oss-process=image/resize,h_${height},m_lfit`;
		}
	}
	if (text) {
		let btoaSrc = Buffer.from(text, 'utf-8').toString('base64');
		btoaSrc = btoaSrc.replace(/\+/g, '-');
		btoaSrc = btoaSrc.replace(/\//g, '_');
		if (btoaSrc.endsWith('=')) {
			btoaSrc = btoaSrc.replace(/=+$/, "");
		}
		
		src += `,image/watermark,text_${btoaSrc},color_FFFFFF,shadow_50,size_${size}`;
	}
	return src
}