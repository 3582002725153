<template>
  <l-dialog title="密码" :visible="visible" @close="changeVisible"  :confirm-fn="judyPassword">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-form-item label="密码" prop="password">
        <el-input placeholder="请输入密码" v-model="form.password" size="small" type="password"/>
      </el-form-item>
    </el-form>
  </l-dialog>
</template>

<script>
import LDialog from "@/components/l-dialog/l-dialog.vue";
import {mapMutations, mapState} from "vuex";
import OrderApi from "@/api/OrderApi";

export default {
  name: "l-checkPassword",
  components: {LDialog},
  data(){
    return{
      form:{
        password:''
      },
      rules:{
        password:{
          required:true,
          message:'请输入密码'
        }
      }
    }
  },
  computed: {
    ...mapState('base', ['visible','to'])
  },
  methods: {
    ...mapMutations('base', ['changeVisible','changeChecked']),
    // 校验密码
    async judyPassword() {
      await this.$refs.form.validate();
      await OrderApi.judyPassword(this.form.password);
      this.changeChecked();
      this.changeVisible(false)
      await this.$router.push(this.to)
    }
  }
}
</script>


<style scoped lang="scss">

</style>