import request from "@/request";

class AppidApi {
    /**
    * @Description:根据appid换取商家id
    */
    business(){
        return request.get({
            url:"/index/appid/business",
        })
    }
}
export default new AppidApi()