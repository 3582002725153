import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/layout/Layout.vue'),
        redirect: '/index',
        children: [
            {
                path: "/index",
                component: () => import('@/views/index/index.vue')
            },{
                path:"/adParse",
                component:()=>import('@/views/index/adParse.vue')
            }, {
                path: "/login",
                component: () => import('@/views/base/login.vue')
            }, {
                path: "/styleHall",
                component: () => import('@/views/styleHall/styleHall.vue')
            }, {
                path: "/brandPavilion",
                component: () => import('@/views/brandPavilion/brandPavilion.vue')
            }, {
                path: "/brandDetail",
                component: () => import('@/views/brandPavilion/brandDetail.vue')
            }, {
                path: '/goods',
                component: () => import('@/views/goods/goods.vue')
            }, {
                path: '/goodsDetail',
                component: () => import('@/views/goods/goodsDetail.vue')
            }, {
                path: "/joinInCooperation",
                component: () => import('@/views/joinInCooperation/joinInCooperation.vue')
            }, {
                path: "/notice",
                component: () => import('@/views/notice/notice.vue')
            }, {
                path: "/shoppingCart",
                component: () => import('@/views/shoppingCart/shoppingCart.vue')
            }, {
                path: "/pay",
                component: () => import('@/views/shoppingCart/pay/pay.vue')
            }, {
                path: "/success",
                component: () => import('@/views/shoppingCart/success.vue')
            },{
                path: "/buyersShow",
                component: () => import('@/views/buyersShow/buyersShow.vue')
            }]
    }, {
        path: '/personal',
        component: () => import('@/layout/PersonalLayout.vue'),
        redirect: '/personal/layout',
        children: [
            {
            path: "/personal/layout",
            component: () => import('@/views/personal/index.vue'),
            redirect: "/personal/layout/personal",
            children: [{
                path: "/personal/layout/personal",
                component: () => import('@/views/personal/personal.vue')
            }, {
                path: "/personal/layout/accountSecurity",
                component: () => import('@/views/personal/accountSecurity.vue')
            }, {
                path: "/personal/layout/changePassword",
                component: () => import('@/views/personal/changePassword.vue')
            }, {
                path: "/personal/layout/mobileCheck",
                component: () => import('@/views/personal/mobileCheck.vue')
            }, {
                path: "/personal/layout/mobileChange",
                component: () => import('@/views/personal/mobileChange.vue')
            }, {
                path: "/personal/layout/purchasingOrder",
                component: () => import('@/views/personal/purchasingOrder.vue'),
                meta:{
                    needPassword:true
                }
            }, {
                path: "/personal/layout/userInfo",
                component: () => import('@/views/personal/userInfo.vue')
            }, {
                path: "/personal/layout/message",
                component: () => import('@/views/personal/message.vue')
            }, {
                path: "/personal/layout/question",
                component: () => import('@/views/personal/question.vue')
            }, {
                path: "/personal/layout/address",
                component: () => import('@/views/personal/address.vue')
            }, {
                path: "/personal/layout/order",
                component: () => import('@/views/personal/order/order.vue')
            }]
        },
            {
                path: "/personal/footPrint",
                component: () => import('@/views/personal/footPrint.vue')
            },
            {
                path: "/personal/collection",
                component: () => import('@/views/personal/collection.vue')
            },
            {
                path: "/personal/comment",
                component: () => import('@/views/personal/comment.vue')
            }, {
                path: "/personal/orderDetail",
                component: () => import('@/views/personal/order/orderDetail.vue')
            }, {
                path: "/personal/chat",
                component: () => import('@/views/personal/chat.vue')
            },
            {
                path:"/personal/helpCenter",
                component:()=>import('@/views/personal/helpCenter/helpCenter.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to,form,next)=>{
    const checked=store.state.base.checked;
    if(to.meta.needPassword&&!checked){
        next(false);
        store.commit('base/changeVisible');
        store.commit('base/changeTo',to)
    }else{
        next()
    }
})
router.afterEach((to,from) => {
    if(from.meta.needPassword){
        store.commit('base/changeChecked')
    }
})

export default router
