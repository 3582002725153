import VipApi from "@/api/vipApi";
import storageUtils from "@/utils/storageUtils";
import CartApi from "@/api/cartApi";

const user = {
	namespaced: true,
	state: {
		user: null,
		cartData: [],//购物车商品信息
		cartTotal: 0,//购物车总数
		orderGoodsList: [],//订单商品
	},
	mutations: {
		// 修改用户信息
		changeUser(state, user){
			storageUtils.setItem('user', user)
			state.user = user;
		},
		// 修改购物车
		changeCart(state, cart = []){
			storageUtils.setItem('cart', cart)
			state.cartTotal = cart.length;
			state.cartData = cart;
		},
		// 重置掉所有的用户数据
		resetData(state){
			storageUtils.removeItem('user');
			storageUtils.removeItem('cart');
			state.user = null;
			state.cartTotal = 0;
			state.cartData = []
		},
		//  修改订单商品
		changeOrderGoodsList(state, orderGoodsList){
			orderGoodsList = orderGoodsList || []
			storageUtils.setItem('orderGoodsList', orderGoodsList);
			state.orderGoodsList = orderGoodsList;
		}
	},
	getters: {},
	actions: {
		// 获取用户信息
		async get(context){
			const res = await VipApi.get();
			context.commit('changeUser', res)
			return res
		},
		// 获取购物车
		async queryCart(context){
			const res = await CartApi.query();
			context.commit('changeCart', res);
			return res
		}
	},
};
export default user;
