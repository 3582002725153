class storageUtils {
    setItem(key,val){
        localStorage.setItem(key,JSON.stringify(val))
    }
    getItem(key){
        const val=localStorage.getItem(key)
        if(!val) return null
        try {
            return JSON.parse(val)
        }catch (e) {
            return val
        }
    }
    removeItem(key){
        localStorage.removeItem(key)
    }
    clear(){
        localStorage.clear()
    }
}

export default new storageUtils()