import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import * as filters from '@/filters';
import store,{setupStore} from "@/store";
import VueClipboard from 'vue-clipboard2'
import '@/styles/index.scss'
Vue.config.productionTip = false
Vue.use(ElementUI);


Vue.use(VueClipboard)
//过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
setupStore()
